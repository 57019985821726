var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.prefixCls, _vm.reverseColor && "reverse-color"] },
    [
      _c(
        "span",
        [
          _vm._t("term"),
          _c("span", { staticClass: "item-text" }, [_vm._t("default")], 2),
        ],
        2
      ),
      _c(
        "span",
        { class: [_vm.flag] },
        [_c("a-icon", { attrs: { type: `caret-${_vm.flag}` } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }